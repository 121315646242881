import React from 'react';
import { Box, Chip, Collapse, Grid, IconButton, Stack, Typography } from '@mui/material';
import { CaretDown } from '@phosphor-icons/react';
import { CaretRight } from '@phosphor-icons/react/dist/ssr';

interface ExclusionProps {
  title: string;
  exclusions?: string[];
  length: number;
}

export const Exclusion: React.FC<ExclusionProps> = ({
  title,
  exclusions,
  length,
}) => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <>
      <Grid item xs={5}
        onClick={() => setExpanded(old => !old)}
        sx={{
          cursor: 'pointer',
        }}
      >
        <Typography variant='subtitle2' fontWeight={600}>
          {title}
        </Typography>
      </Grid>

      <Grid item xs={7}
        onClick={() => setExpanded(old => !old)}
        sx={{
          cursor: 'pointer',
        }}
      >
        {length ? (
          <IconButton
            size='small'
            sx={{
              '&:hover': {
                backgroundColor: 'transparent !important',
              },
              p: 0,
              gap: '0.5rem',
            }}
          >
            {expanded ? <CaretDown /> : <CaretRight />}
            <Typography variant='subtitle2' color='textSecondary'>
              {length} items
            </Typography>
          </IconButton>
        ) : (
          <Typography variant='subtitle2' color='textSecondary'>
              N/A
          </Typography>
        )}
      </Grid>

      {!!length && (
        <Box pl='0.25rem'>
          <Collapse in={expanded}>
            <Stack direction='row' flexWrap='wrap' pt='0.5rem'>
              {exclusions?.map((label, index) => {
                return (
                  <Chip
                    key={`${label}-${index}`}
                    label={label}
                  />
                );
              })}
            </Stack>
          </Collapse>
        </Box>
      )}
    </>
  );
};