import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Stack, Alert } from '@mui/material';
import { FormikControlMui } from '@shared/components/formik';
import { muiPalette } from '@shared/general-mui-theme';
import { Exclusion } from './components';

export interface TargetExclusionsProps {
  targetsCount?: number;
  isApiTarget?: boolean;
}

export const TargetExclusions: React.FC<TargetExclusionsProps> = ({
  targetsCount = 1,
  isApiTarget = false,
}) => {

  return (
    <Stack>
      <Accordion>
        <AccordionSummary
          sx={{
            '& .MuiAccordionSummary-content': {
              alignItems: 'center',
            },
          }}
        >
          <Typography fontWeight='500'>Exclusions</Typography>
          <Typography
            variant='subtitle2'
            color={muiPalette.grey?.[500]}
            sx={{ opacity: '0.5' }}
          >
            &nbsp;(optional)
          </Typography>
        </AccordionSummary>
        {targetsCount === 1 ? (
          <AccordionDetails>
            <Stack gap='1rem'>
              <FormikControlMui name={'configuration.excluded_url_patterns'} label='Exclude URL patterns' fullWidth>
                <Exclusion fieldName='excluded_url_patterns' placeholder='Enter URLs or regex'/>
              </FormikControlMui>

              {!isApiTarget && (
                <FormikControlMui name={'configuration.excluded_x_paths'} label='Exclude clicks based on XPath' fullWidth>
                  <Exclusion fieldName='excluded_x_paths' placeholder='Exclude clicks based on XPath'/>
                </FormikControlMui>
              )}
            </Stack>
          </AccordionDetails>
        ) : (
          <AccordionDetails>
            <Alert severity='info'>
              {'You have multiple targets. Configure each one after creation.'}
            </Alert>
          </AccordionDetails>
        )}
      </Accordion>
    </Stack>
  );
};