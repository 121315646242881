
import * as Yup from 'yup';
import { harTypes } from '.';
import { globalQueries } from '@shared/duck';
import { FormikHelpers } from 'formik';
import { appConstants } from '@app/components/context/provider/duck';
import { CanceledError } from 'axios';
import { enqueueSnackbar } from 'notistack';

declare module 'yup' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ArraySchema<T> {
    unique(field: string, path: string): this;
  }
}

// Yup.addMethod(Yup.array, 'unique', function (message, path) {
//   return this.test('unique', message, function (list) {
//     const mapper = (x: any) => x[path];
//     const seen: Record<string, number[]> = {};

//     // Track all occurrences of each value
//     list?.forEach((item, index) => {
//       const value = mapper(item);
//       if (seen[value]) {
//         seen[value].push(index);
//       } else {
//         seen[value] = [index];
//       }
//     });

//     // Find duplicates (values that occur more than once)
//     const duplicateErrors = Object.values(seen)
//       .filter((indices) => indices.length > 1)
//       .flatMap((indices) => indices.slice(1)); // Take all duplicates except the first

//     if (duplicateErrors.length === 0) {
//       return true; // No duplicates
//     }

//     const errorMessages = duplicateErrors.map((index) =>
//       this.createError({
//         path: `${this.path}[${index}].${path}`,
//         message,
//       })
//     );

//     return new Yup.ValidationError(errorMessages);
//   });
// });

Yup.addMethod(Yup.array, 'unique', function (message, path) {
  return this.test('unique', message, function (list) {
    const mapper = (x: any) => {
      if(x && (path in x)){
        return x[path];
      } else {
        return undefined;
      }
    };

    list = list?.filter(obj => !obj.deleted);
    const set = [...new Set(list?.map(mapper))];
    const isUnique = list?.length === set.length;
    if (isUnique) {
      return true;
    }

    const idx = list?.findIndex((l, i) => mapper(l) !== set[i]);
    return this.createError({ path: `${this.path}[${idx}].${path}`, message });
  });
});

export const harFilesValidationSchema = Yup.object().shape({
  values: Yup.array()
    .of(
      Yup.object().shape({
        file: Yup.mixed().nullable().required('Please attach a HAR file'),
        fileName: Yup.string().required('Please enter file name')
          .when('status', {
            is: harTypes.HAR_UPLOAD_STATUS.ERROR,
            then: schema => schema.test('Error', 'Error uploading HAR file', () => false),
          })
          .when('status', {
            is: harTypes.HAR_UPLOAD_STATUS.UPLOAD_ERROR,
            then: schema => schema.test('Error', 'Error uploading HAR file', () => false),
          })
          .when('status', {
            is: harTypes.HAR_UPLOAD_STATUS.DUPLICATE_NAME,
            then: schema => schema.test('Error', 'HAR file with this name already exists', () => false),
          }),
        status: Yup.number().notOneOf([harTypes.HAR_UPLOAD_STATUS.ERROR, harTypes.HAR_UPLOAD_STATUS.UPLOAD_ERROR], 'Invalid status'),
        uploadUrl: Yup.string(),
      })
    )
    .nullable()
    .min(1, 'At least one file required')
    .unique('File names must be unique', 'fileName'),
});

export const useSubmit = (targetId: string) => {
  const { createHarFile } = globalQueries.useCreateHarFile();
  const { markHarFileAsUploadedToBucket } = globalQueries.useMarkHarFileAsUploadedToBucket();
  const { markHarFileAsErrorUploadToBucket } = globalQueries.useMarkHarFileAsErrorUploadToBucket();
  const { deleteHarFile } = globalQueries.useDeleteHarFile();

  const onSubmit = async (values: harTypes.HarUploadModalValues, formikHelpers: FormikHelpers<harTypes.HarUploadModalValues>): Promise<boolean> => {
    let hasErrors = false;
    await Promise.all(
      values.values.map(async (harObj, index) => {
        if (harObj.status === harTypes.HAR_UPLOAD_STATUS.UPLOADED || harObj.deleted) return;
        try {
          formikHelpers.setFieldValue(`values[${index}]`, { ...harObj,status: harTypes.HAR_UPLOAD_STATUS.SUBMITTING }, false);

          const { data } = await createHarFile({
            id: targetId,
            harFileCreateRequest: { name: harObj.fileName },
            signal: harObj.abortController?.signal,
          });
          if (data.upload_url) {
            const file = harObj.file;

            try {
              await appConstants.AxiosClient.put(data.upload_url, file, {
                headers: { 'Content-Type': '' },
                onUploadProgress: (progressEvent) => {
                  const progress = (progressEvent.loaded / (progressEvent.total || 1)) * 100;
                  formikHelpers.setFieldValue(`values[${index}].progress`, Math.floor(progress));
                },
                signal: harObj.abortController?.signal
              });

              await markHarFileAsUploadedToBucket({ id: targetId, harFileId: data.id, signal: harObj.abortController?.signal });

              formikHelpers.setFieldValue(`values[${index}]`, { ...harObj, id: data.id, uploadUrl: data.upload_url, status: harTypes.HAR_UPLOAD_STATUS.UPLOADED, progress: 100 }, false);
            }
            catch (e) {
              if (e instanceof CanceledError) {
                // canceled uploading to s3
                try {
                  await markHarFileAsErrorUploadToBucket({ id: targetId, harFileId: data.id });
                  await deleteHarFile({ id: targetId, harFileId: data.id });
                  formikHelpers.setFieldValue(`values[${index}]`, { ...harObj, deleted: true }, false);
                }
                catch {
                  enqueueSnackbar(`Failed to cancel the upload of file ${data.name}`);
                }
                return;
              }
              hasErrors = true;
              await markHarFileAsErrorUploadToBucket({ id: targetId, harFileId: data.id });
              formikHelpers.setFieldValue(`values[${index}]`, { ...harObj, id: data.id, uploadUrl: data.upload_url, status: harTypes.HAR_UPLOAD_STATUS.UPLOAD_ERROR, progress: 0 }, false);
            }
          }
        }
        catch (error: any) {
          if (error instanceof CanceledError) {
            // canceled har file creation
            formikHelpers.setFieldValue(`values[${index}]`, { ...harObj, deleted: true }, false);
            return;
          }
          hasErrors = true;
          if (error?.response?.data['errors'][0].code === 'duplicate_name') {
            formikHelpers.setFieldValue(`values[${index}]`, { ...harObj, status: harTypes.HAR_UPLOAD_STATUS.DUPLICATE_NAME, progress: 0 }, false);
          }
          else {
            formikHelpers.setFieldValue(`values[${index}]`, { ...harObj, status: harTypes.HAR_UPLOAD_STATUS.ERROR }, false);
          }
        }
      })
    );
    return !hasErrors;
  };

  return {
    onSubmit,
  };
};
