import {
  Table as BaseTable,
  TextLink,
  TableRowProps as BaseTableRowProps,
  CommonProps,
  IconButton
} from '@contentful/f36-components';
import { get, groupBy, memoize } from 'lodash';
import styles from '@shared/general-styles.css';
import { ChevronDownIcon, ChevronUpIcon, LoadMoreIcon } from '../Icons';
import { ColumnProps, TableProps } from './Table.interfaces';
import { globalEnums, globalTypes } from '@shared/duck';
import {
  TableSpinner,
  TableCell as StyledTableCell,
  TableWrapper,
  ExpandedRow,
  LoadMoreWrapper,
  StyledGroupRow,
} from './Table.styled';
import TableColumnSortButton from './TableColumnSortButton';
import useTableSorting from './useTableSorting';
import React from 'react';
import { UICheckbox } from '@shared/components';
import { Box, CircularProgress, Stack } from '@mui/material';

function calculateTotalColSpan<DataItem>({
  columns = [],
  itemSelectionApi,
  rowExpandRenderer,
  itemActionsRenderer,
}: Partial<TableProps<DataItem>>) {
  const selectable = Boolean(itemSelectionApi);
  const expandable = Boolean(rowExpandRenderer);
  const withActions = Boolean(itemActionsRenderer);

  return columns.length + (
    expandable ? 1 : 0
  ) + (
    selectable ? 1 : 0
  ) + (
    withActions ? 1 : 0
  );
}

function TableCell<DataItem>({
  item,
  column,
  index,
}: {
  item: DataItem
  column: ColumnProps<DataItem>
  index: number
}) {
  const { formatter, renderer } = column;
  const value = get(item, column.columnKey);
  const formattedValue = formatter ? formatter(value, item) : value;
  const renderedValue = renderer ? renderer(formattedValue, item, index) : <>{formattedValue}</>;

  return (
    <StyledTableCell
      width={column.width}
      paddingLeft={column.paddingLeft}
      paddingRight={column.paddingRight}
      className={`body-cell ${column.columnKey}`}
    >
      {renderedValue}
    </StyledTableCell>
  );
}

interface TableRowProps<DataItem> extends Partial<BaseTableRowProps> {
  item: DataItem;
  index: number;
  tableProps: Partial<TableProps<DataItem>>;
}

function TableRow<DataItem extends globalTypes.UIDItem>(props: TableRowProps<DataItem>) {
  const {
    item,
    index,
    tableProps: {
      itemSelectionApi,
      onRowClick = () => undefined,
      rowExpandRenderer,
      selectedRowId,
      columns = [],
      itemActionsRenderer,
      expandedRowId,
      afterDelete = false,
      expandOnRowClick = false,
    },
  } = props;

  const [isExpanded, setIsExpanded] = React.useState(Boolean(expandedRowId === item.id));

  React.useEffect(() => {
    if (expandedRowId === item.id && !isExpanded)
      setIsExpanded(true);
  }, [expandedRowId, afterDelete]);

  const selectable = Boolean(itemSelectionApi);
  const expandable = Boolean(rowExpandRenderer);

  const onExpandClick = React.useCallback(
    (e?: any) => {
      e?.stopPropagation();
      setIsExpanded(!isExpanded);
    },
    [isExpanded],
  );

  const getOnRowClick = React.useCallback(
    memoize((item: DataItem) => () => {
      if (expandOnRowClick) {
        onExpandClick();
      }

      onRowClick(item);
    }),
    [onRowClick, expandOnRowClick, onExpandClick],
  );

  const getOnToggleCheckRow = React.useCallback(
    memoize((item: DataItem) => () => itemSelectionApi?.onToggleItem(item)),
    [itemSelectionApi],
  );

  const expandableColSpan = calculateTotalColSpan(props.tableProps);
  const isSelected = !!itemSelectionApi?.selectedItems.has(item.id) || selectedRowId === item.id;
  const rowClassName = isSelected ? [styles.HoverPointer, 'selected'].join(' ') : styles.HoverPointer;

  return (
    <>
      <BaseTable.Row isSelected={isSelected} onClick={getOnRowClick(item)} className={rowClassName}>
        {selectable ? (
          <StyledTableCell small={globalEnums.EmotionBoolean.True}>
            <Box
              p='0.75rem'
              pl='0'
              onClick={(e: { stopPropagation: () => void }) => e.stopPropagation()}
            >
              <UICheckbox
                onChange={getOnToggleCheckRow(item)}
                isChecked={itemSelectionApi?.selectedItems.has(item.id)}
                onClick={e => e.stopPropagation()}
              />
            </Box>
          </StyledTableCell>
        ) : null}

        {expandable ? (
          <StyledTableCell width='2.5rem' paddingLeft='0rem' paddingRight='0rem'>
            <IconButton
              icon={isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
              aria-label='expand'
              onClick={onExpandClick}
            />
          </StyledTableCell>
        ) : null}

        {columns.map(col => (
          <TableCell<DataItem> column={col} item={item} key={col.title} index={index} />
        ))}

        {itemActionsRenderer ? (
          <StyledTableCell onClick={e => e.stopPropagation()} small={globalEnums.EmotionBoolean.True}>
            {itemActionsRenderer(item)}
          </StyledTableCell>
        ) : null}
      </BaseTable.Row>
      {isExpanded ? (
        <ExpandedRow>
          <StyledTableCell colSpan={expandableColSpan} padding='0rem !important'>
            {rowExpandRenderer ? rowExpandRenderer(item) : null}
          </StyledTableCell>
        </ExpandedRow>
      ) : null}
    </>
  );
}

function Table<DataItem extends globalTypes.UIDItem>(props: TableProps<DataItem> & CommonProps): JSX.Element {
  const {
    data,
    columns,
    scrollDown = false,
    loading = false,
    isLoadMoreAvailable = false,
    onLoadMoreClick,
    variant = 'inline',
    initialSortBy,
    onSortByChanged,
    itemSelectionApi,
    bulkActions,
    itemActionsRenderer,
    rowExpandRenderer,
    colorTheme = 'light',
    className,
    groupRowsBy = () => '',
    minWidth,
    onRowClick,
    withSelectAllCheckbox = true,
    ...others
  } = props;

  const selectable = Boolean(itemSelectionApi);
  const expandable = Boolean(rowExpandRenderer);
  const rowsClickable = Boolean(onRowClick);
  const groupedItems = React.useMemo(() => groupBy(data, groupRowsBy), [data, groupRowsBy]);

  const { sortBy, onSortColumnClick } = useTableSorting<DataItem>({
    initialSortBy,
    onSortByChanged,
  });

  const getOnSortColumnClick = React.useCallback(
    memoize((col: ColumnProps<DataItem>) => () => onSortColumnClick(col.columnKey)),
    [onSortColumnClick],
  );

  const scrollEndRef = React.createRef<HTMLDivElement>();

  // Scroll to end when a new batch is fetched
  React.useEffect(() => {
    if (!loading && data.length > 0 && scrollEndRef.current) {
      scrollEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [data.length, loading]);

  const [isFetching, setIsFetching] = React.useState(false);
  const onLoadMoreWrapper = async () => {
    setIsFetching(true);
    await onLoadMoreClick?.();
    setIsFetching(false);
  };

  return (
    <TableWrapper
      colorTheme={colorTheme}
      style={{ ...others.style }}
      isEmpty={data.length > 0 ? globalEnums.EmotionBoolean.False : globalEnums.EmotionBoolean.True}
      className={className}
      minWidth={minWidth}
      rowsClickable={rowsClickable || expandable ? globalEnums.EmotionBoolean.True : globalEnums.EmotionBoolean.False}
    >
      <div style={{ ...others.style, minWidth: minWidth, overflow: 'auto' }}>
        <BaseTable layout={variant}>
          <BaseTable.Head style={{ position: 'sticky', top: '0', zIndex: 10 }}>
            <BaseTable.Row>
              {selectable ? (
                <StyledTableCell small={globalEnums.EmotionBoolean.True}>
                  <Stack direction='row' gap='0' className='checkbox-cell'>
                    {withSelectAllCheckbox ? (
                      <UICheckbox
                        onChange={itemSelectionApi?.onToggleAll}
                        isIndeterminate={itemSelectionApi?.isPartiallySelected}
                        isChecked={itemSelectionApi?.isAllSelected || itemSelectionApi?.isPartiallySelected}
                      />
                    ) : (
                      <></>
                    )}
                    {bulkActions}
                  </Stack>
                </StyledTableCell>
              ) : null}

              {expandable ? <StyledTableCell /> : null}

              {columns.map(col => {
                const headContent = col.headRenderer ? col.headRenderer(col) : col.title;
                return (
                  <StyledTableCell
                    key={col.title}
                    width={col.width}
                    paddingLeft={col.paddingLeft}
                    paddingRight={col.paddingRight}
                    className={`header-cell ${col.columnKey}`}
                  >
                    {col.sortable ? (
                      <TableColumnSortButton
                        sorting={sortBy?.columnKey === col.columnKey ? sortBy.order : undefined}
                        onClick={getOnSortColumnClick(col)}
                      >
                        {headContent}
                      </TableColumnSortButton>
                    ) : (
                      headContent
                    )}
                  </StyledTableCell>
                );
              })}
              {itemActionsRenderer ? <StyledTableCell width='1.875rem' /> : null}
            </BaseTable.Row>
            {loading ? <TableSpinner colSpan={columns.length} /> : <></>}
          </BaseTable.Head>
          <BaseTable.Body>
            <>
              {Object.entries(groupedItems).map(([group, items]) => (
                <React.Fragment key={group}>
                  {group ? (
                    <StyledGroupRow>
                      <BaseTable.Cell colSpan={calculateTotalColSpan(props)}>{group}</BaseTable.Cell>
                    </StyledGroupRow>
                  ) : null}
                  {items.map((item, index) => (
                    <TableRow<DataItem> item={item} index={index} key={item.id} tableProps={props} />
                  ))}
                </React.Fragment>
              ))}
            </>
          </BaseTable.Body>
        </BaseTable>
        {isLoadMoreAvailable && (
          <div style={{ minWidth: minWidth }}>
            <LoadMoreWrapper
              colorTheme={colorTheme}
            >
              <TextLink onClick={onLoadMoreWrapper} data-testid='load-more-icon' isDisabled={isFetching}>
                <Stack direction='row' sx={{ cursor: isFetching ? 'not-allowed' : 'pointer', alignItems: 'center' }}>
                  <LoadMoreIcon height={14} /> Load more results
                  {isFetching && (
                    <CircularProgress size={18} />
                  )}
                </Stack>
              </TextLink>
            </LoadMoreWrapper>
          </div>
        )}
        {scrollDown && <div ref={scrollEndRef}></div>}
      </div>
    </TableWrapper>
  );
}

export default Table;
