import { Target } from '@api-client';
import { Box, Button, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useCreateOrEditTargetsHandler, useDeleteTargetHandler, useMedia } from '@shared/hooks';
import { allRoutesEnum, globalEnums, globalUtils } from '@shared/duck';
import { useNavigate } from 'react-router-dom';
import { PencilSimple, Trash } from '@phosphor-icons/react';
import { CopyIconC } from '@assets';
import { enqueueSnackbar } from 'notistack';

interface CommonTargetHeaderProps {
  target?: Target;
}

const CommonTargetHeader: React.FC<CommonTargetHeaderProps> = ({
  target,
}: CommonTargetHeaderProps) => {
  const { deleteTargetHandler } = useDeleteTargetHandler();
  const navigate = useNavigate();
  const Icon = globalUtils.getTargetTypeIcon(
    target?.type.toString()?.toUpperCase() as globalEnums.ETargetType,
    undefined,
    {
      height: '2.5rem',
      width: '2.5rem',
    },
  );
  const targetViewModel = React.useMemo(() => {
    return target && globalUtils.mapTargetsDtoToViewModel(target);
  }, [target]);
  const { isSmallScreen } = useMedia();
  const { createOrEditTargetHandler } = useCreateOrEditTargetsHandler();

  const onEditTargetClick = () => {
    createOrEditTargetHandler({ enableProjectSelect: false, target: targetViewModel });
  };

  const onDeleteClick = () => {
    targetViewModel && deleteTargetHandler(targetViewModel, () =>
      navigate(allRoutesEnum.Targets));
  };

  const onCopyTargetName = () => {
    navigator.clipboard.writeText(target?.name || '')
      .then(() => {
        enqueueSnackbar('Copied to clipboard!', { variant: 'info', autoHideDuration: 1000, closeBtn: false });
      });
  };

  return (
    <Stack
      flexDirection='row'
      justifyContent='space-between'
      alignItems='center'
    >
      <Stack
        flexDirection='row'
        alignItems='center'
        minWidth='10rem'
      >
        {Icon && (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            {Icon}
          </Box>
        )}

        <Tooltip
          arrow
          placement='top'
          title={target?.name || target?.location || ''}
        >
          <Typography
            noWrap
            variant='h5'
          >
            {target?.name || target?.location}
          </Typography>
        </Tooltip>

        <CopyIconC
          onClick={onCopyTargetName}
        />
      </Stack>

      <Stack
        flexDirection='row'
      >
        {isSmallScreen ? (
          <IconButton
            aria-label='Edit Target'
            color='outlined'
            onClick={onEditTargetClick}
          >
            <PencilSimple size={16} />
          </IconButton>
        ) : (
          <Button
            onClick={onEditTargetClick}
          >
            Edit Target
          </Button>
        )}

        {isSmallScreen ? (
          <IconButton
            aria-label='Delete Action'
            color='error'
            onClick={onDeleteClick}
          >
            <Trash size={16} />
          </IconButton>
        ) : (
          <Button
            onClick={onDeleteClick}
            color='error'
            variant='outlined'
          >
            Delete Target
          </Button>
        )}

      </Stack>
    </Stack>
  );
};

export default CommonTargetHeader;