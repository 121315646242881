import * as apiClient from 'api-client';
import {
  InfiniteQueryObserverOptions,
  QueryObserverOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  Target,
  TargetsApiTargetsCreateRequest,
  TargetsApiTargetsDestroyRequest,
  TargetsApiTargetsFalsePositivesCreateRequest,
  TargetsApiTargetsFalsePositivesDeleteCreateRequest,
  TargetsApiTargetsFalsePositivesListRequest,
  TargetsApiTargetsFalsePositivesUpdateRequest,
  TargetsApiTargetsListRequest,
  TargetsApiTargetsRetrieveRequest,
  TargetsApiTargetsTargetAuthListRequest,
} from 'api-client';
import { AxiosError, AxiosResponse } from 'axios';
import { useApp } from '@shared/hooks';
import { TargetsQueries } from './queriesKeys';
import { getNextPageParam, useFlatCountFromResponse, useFlatResults } from './utils';
import { globalUtils } from '..';

const useGetTargetsList = (request: TargetsApiTargetsListRequest) => {
  const { targetsApi } = useApp();
  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteQuery({
    queryKey: [TargetsQueries.targetsList, ...Object.values(request)],
    queryFn: ({ pageParam = request.page || 1 }) => targetsApi.targetsList({ ...request, page: pageParam }),

    getNextPageParam: getNextPageParam,
  });

  const targets = useFlatResults(data);
  const totalItemsCount = useFlatCountFromResponse(data)[0] as unknown as number;

  return {
    targets,
    totalItemsCount,
    isTargetsListLoading: isLoading || isFetchingNextPage,
    targetsHasNextPage: hasNextPage,
    fetchNextTargetsPage: fetchNextPage,
  };
};

const useGetTarget = (
  request: TargetsApiTargetsRetrieveRequest,
  config?: Partial<QueryObserverOptions<AxiosResponse<Target>, AxiosError>>,
) => {
  const { targetsApi } = useApp();
  const { data, isLoading } = useQuery({
    queryKey: [TargetsQueries.targetById, ...Object.values(request)],
    queryFn: () => targetsApi.targetsRetrieve({ ...request }),
    enabled: !!request.id,
    ...config,
  });

  return {
    target: data?.data,
    isTargetLoading: request.id ? isLoading : false,
  };
};

const useGetTargetMutation = () => {
  const { targetsApi } = useApp();
  const { mutateAsync } = useMutation({
    mutationFn: (request: TargetsApiTargetsRetrieveRequest) => targetsApi.targetsRetrieve(request),
  });

  return {
    getTarget: mutateAsync,
  };
};

const useDeleteTarget = () => {
  const queryClient = useQueryClient();
  const { targetsApi } = useApp();

  const { mutateAsync } = useMutation({
    mutationFn: (request: TargetsApiTargetsDestroyRequest) => targetsApi.targetsDestroy(request),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: [TargetsQueries.targetsList] });
      queryClient.resetQueries({ queryKey: [TargetsQueries.urlTargetsList] });
    },
  });

  return {
    deleteTarget: mutateAsync,
  };
};

const useCreateTarget = () => {
  const { targetsApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (request: TargetsApiTargetsCreateRequest) => targetsApi.targetsCreate(request),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: [TargetsQueries.urlTargetsList] });
      queryClient.resetQueries({ queryKey: [TargetsQueries.targetsList] });
    },
  });

  return { createTargets: mutateAsync };
};

const useGetTargetsAuthList = (
  request: TargetsApiTargetsTargetAuthListRequest,
  config: Partial<InfiniteQueryObserverOptions<AxiosResponse<apiClient.PaginatedScanList>, AxiosError>> = {},
) => {
  const { targetsApi } = useApp();
  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: [TargetsQueries.targetsAuthList, ...Object.values(request)],
    queryFn: ({ pageParam = request.page || 1 }) => targetsApi.targetsTargetAuthList({ ...request, page: pageParam }),

    getNextPageParam: getNextPageParam,
    ...config,
    enabled: !!request.id,
  });

  const scans = useFlatResults(data);
  const authentications = scans.map(item => item.credentials).filter(globalUtils.notEmpty);
  const totalItemsCount = useFlatCountFromResponse(data)[0] as unknown as number;

  return {
    authentications,
    totalItemsCount,
    isAuthListLoading: isLoading,
    authHasNextPage: hasNextPage,
    fetchNextAuthPage: fetchNextPage,
  };
};

const useCheckPublicUrl = (signal?: AbortSignal) => {
  const { targetsApi } = useApp();

  const { mutateAsync, isLoading, reset } = useMutation({
    mutationFn: async (url: string) => {
      url = url.trim();
      if (url.match(HTTPS_PARTS_REGEX)) {
        return getCheckUrlErrorResponse(url, 400);
      }
      const response = await targetsApi.targetsCheckPublicUrlCreate(
        { targetTestUrlRequest: { url } },
        { validateStatus: null, signal: signal },
      );
      if (response.status !== 200) {  // API error
        return getCheckUrlErrorResponse(url, response.status);
      }
      return response;
    },
  });

  return {
    checkPublicUrl: mutateAsync,
    isLoading: isLoading,
    resetMutation: reset,
  };
};

const useGetFalsePositives = (request: TargetsApiTargetsFalsePositivesListRequest) => {
  const { targetsApi } = useApp();

  const { data, isLoading } = useInfiniteQuery({
    queryKey: [TargetsQueries.falsePositivesList, ...Object.values(request)],
    queryFn: ({ pageParam = request.page || 1 }) => targetsApi.targetsFalsePositivesList({
      ...request,
      page: pageParam,
    }),
    getNextPageParam: getNextPageParam,
  });

  const falsePositives = useFlatResults(data);
  const totalItemsCount = useFlatCountFromResponse(data)[0] as unknown as number;

  return {
    falsePositives,
    totalItemsCount,
    isFpListLoading: isLoading,
  };
};

const useCreateFalsePositives = () => {
  const { targetsApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (request: TargetsApiTargetsFalsePositivesCreateRequest) => targetsApi.targetsFalsePositivesCreate(
      request),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: [TargetsQueries.falsePositivesList] });
    },
  });

  return {
    createFalsePositives: mutateAsync,
  };
};

const useUpdateFalsePositives = () => {
  const { targetsApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (request: TargetsApiTargetsFalsePositivesUpdateRequest) => targetsApi.targetsFalsePositivesUpdate(
      request),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: [TargetsQueries.falsePositivesList] });
    },
  });

  return {
    updateFalsePositives: mutateAsync,
  };
};

const useDeleteFalsePositives = () => {
  const queryClient = useQueryClient();
  const { targetsApi } = useApp();

  const { mutateAsync } = useMutation({
    mutationFn: (request: TargetsApiTargetsFalsePositivesDeleteCreateRequest) => targetsApi.targetsFalsePositivesDeleteCreate(
      request),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: [TargetsQueries.falsePositivesList] });
    },
  });

  return {
    deleteFalsePositives: mutateAsync,
  };
};

const HTTPS_PARTS_REGEX = /^ht?t?t?p?s?:?\/?\/?$/;

function getCheckUrlErrorResponse(
  url: string,
  status: number,
): Pick<AxiosResponse<apiClient.TargetTestUrl, any>, 'data' | 'status'> {
  return {
    data: {
      requested_url: url,
      is_accessible: false,
    },
    status,
  };
}

const useGetHarFiles = (request: apiClient.TargetsApiTargetsHarFilesListRequest) => {
  const { targetsApi } = useApp();

  const { data, isLoading } = useInfiniteQuery({
    queryKey: [TargetsQueries.harFilesList, ...Object.values(request)],
    queryFn: ({ pageParam = request.page || 1 }) => targetsApi.targetsHarFilesList({
      ...request,
      page: pageParam,
    }),
    getNextPageParam: getNextPageParam,
  });

  const harFiles = useFlatResults(data);
  const totalItemsCount = useFlatCountFromResponse(data)[0] as unknown as number;

  return {
    harFiles,
    totalItemsCount,
    isHarListLoading: isLoading,
  };
};

interface CreateHarFileRequest extends apiClient.TargetsApiTargetsHarFilesCreateRequest {
  signal?: AbortSignal;
}

const useCreateHarFile = () => {
  const { targetsApi } = useApp();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (request: CreateHarFileRequest) => targetsApi.targetsHarFilesCreate(request, { signal: request.signal }),
  });

  return {
    createHarFile: mutateAsync,
    isLoading,
  };
};

const useDeleteHarFile = () => {
  const { targetsApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (request: apiClient.TargetsApiTargetsHarFilesDestroyRequest) => targetsApi.targetsHarFilesDestroy(request),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [TargetsQueries.harFilesList] });
    }
  });

  return {
    deleteHarFile: mutateAsync,
    isLoading,
  };
};

const useGetHarDownloadUrl = () => {
  const { targetsApi } = useApp();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (request: apiClient.TargetsApiTargetsHarFilesDownloadUrlRetrieveRequest) =>
      targetsApi.targetsHarFilesDownloadUrlRetrieve(request),
  });

  return {
    getHarDownloadUrl: mutateAsync,
    isLoading,
  };
};

const useEditHarFileName = () => {
  const { targetsApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (request: apiClient.TargetsApiTargetsHarFilesUpdateRequest) =>
      targetsApi.targetsHarFilesUpdate(request),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [TargetsQueries.harFilesList] });
    }
  });

  return {
    editHarFileName: mutateAsync,
    isLoading,
  };
};

interface HarFileUploadSucceededRequest extends apiClient.TargetsApiTargetsHarFilesUploadSucceededCreateRequest {
  signal?: AbortSignal;
}

const useMarkHarFileAsUploadedToBucket = () => {
  const { targetsApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (request: HarFileUploadSucceededRequest) =>
      targetsApi.targetsHarFilesUploadSucceededCreate(request, { signal: request.signal }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [TargetsQueries.harFilesList] });
    }
  });

  return {
    markHarFileAsUploadedToBucket: mutateAsync,
    isLoading,
  };
};

const useMarkHarFileAsErrorUploadToBucket = () => {
  const { targetsApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (request: apiClient.TargetsApiTargetsHarFilesUploadFailedCreateRequest) =>
      targetsApi.targetsHarFilesUploadFailedCreate(request),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [TargetsQueries.harFilesList] });
    }
  });

  return {
    markHarFileAsErrorUploadToBucket: mutateAsync,
    isLoading,
  };
};

const useGetHarFileByName = (signal?: AbortSignal) => {
  const { targetsApi } = useApp();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (request: apiClient.TargetsApiTargetsHarFilesNameRetrieveRequest) =>
      targetsApi.targetsHarFilesNameRetrieve(request, { signal: signal }),
  });

  return {
    getHarFileByName: mutateAsync,
    isLoading,
  };
};

export {
  useGetTargetsList,
  useDeleteTarget,
  useCreateTarget,
  useGetTarget,
  useGetTargetMutation,
  useGetTargetsAuthList,
  useCheckPublicUrl,
  useGetFalsePositives,
  useCreateFalsePositives,
  useUpdateFalsePositives,
  useDeleteFalsePositives,
  useGetHarFiles,
  useCreateHarFile,
  useDeleteHarFile,
  useGetHarDownloadUrl,
  useMarkHarFileAsUploadedToBucket,
  useMarkHarFileAsErrorUploadToBucket,
  useGetHarFileByName,
  useEditHarFileName,
};
